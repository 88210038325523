var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "px-8", attrs: { fluid: "" } },
    [
      _c(
        "v-toolbar",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: this.$route.name != "roles",
              expression: "this.$route.name != 'roles'",
            },
          ],
          staticClass: "mb-4 mt-2",
          attrs: { height: "50", elevation: "4", rounded: "" },
        },
        [_c("ConfigMenu")],
        1
      ),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }